const RockPaperScissors = () => { 

    return (
        <div>
            <h1 className="rps-header">Rock Paper Scissors</h1>
            <h3 className="rps-header2"> REACT TYPESCRIPT CSS HTML</h3>
            <img className='rps-img'src={require('./images/rps-demo.png')} width='300px' alt="" />

        </div>
    )
};

export default RockPaperScissors;